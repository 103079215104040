import * as React from 'react'
import Header from '../Common/Header'
import Footer from '../Common/Footer'
import Container from '../Common/Container'

const Layout = ({ children, location }) => {
  if (location === undefined || typeof location === 'undefined' || !location)
    return null

  // Main page
  if (location?.pathname === '/') {
    return children
  }

  if (location?.pathname === '/landing') {
    return (
      <>
        <Container className="pt-20 lg:pt-0">
          <Header location={location} />
        </Container>
        {children}
        <Footer />
      </>
    )
  }

  if (location?.pathname === '/careers') {
    return (
      <>
        <Container className="pt-20 lg:pt-0">
          <Header location={location} />
        </Container>
        {children}
        <Footer />
      </>
    )
  }

  return (
    <>
      <Container className="pt-20 lg:pt-0">
        <Header location={location} />
        {children}
      </Container>
      <Footer />
    </>
  )
}

export default Layout
