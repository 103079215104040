import { graphql, useStaticQuery } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import * as React from 'react'
import { useContent } from '../../../hooks/useContent'
import { Logo } from '../Header/components/Logo'

const Footer = () => {
  const data = useStaticQuery(graphql`
    query ($content: String = "meta") {
      allMarkdownRemark(
        filter: { frontmatter: { content: { eq: $content } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              instagram
              twitter
              linkedin
            }
          }
        }
      }
    }
  `)
  const content = useContent(data)

  return (
    <footer className="container mx-auto w-full flex flex-col z-10 lg:flex-row space-y-5 lg:justify-between lg:items-center justify-center items-center py-[40px] border-t-[1px] border-t-gray-dark border-t-solid mt-[80px]">
      <Logo />
      <p className="font-medium font-sans text-[14px] text-gray">
        © Copyright 2022 APPS - All Rights Reserved
      </p>
      <div className="flex space-x-5 items-center">
        <a href={content?.twitter} target="_blank" rel="noopener noreferrer">
          <img
            alt="Apps Twitter"
            layout="fixed"
            placeholder="blurred"
            src="../images/icons/svg/icon-twitter.svg"
            width={24}
            loading="eager"
          />
        </a>
        <a href={content?.instagram} target="_blank" rel="noopener noreferrer">
          <img
            alt="Apps Instagram"
            layout="fixed"
            placeholder="blurred"
            src="../images/icons/svg/icon-instagram.svg"
            loading="eager"
          />
        </a>
        <a href={content?.linkedin} target="_blank" rel="noopener noreferrer">
          <img
            alt="Apps Twitter"
            layout="fixed"
            placeholder="blurred"
            src="../images/icons/svg/icon-linkedin.svg"
            loading="eager"
          />
        </a>
      </div>
    </footer>
  )
}

export default Footer
