import React from 'react'
import classNames from 'classnames'

const Button = ({
  text = '',
  icon = null,
  className = null,
  onClick,
  loading = null,
}) => {
  return (
    <button
      className={classNames(
        'group flex justify-center items-center bg-secondary typo-bold-14 lg:typo-bold-16 text-white rounded p-4 px-8',
        { [String(className)]: Boolean(className) }
      )}
      onClick={onClick && onClick}
      disabled={loading}
    >
      <p className="whitespace-nowrap">{text}</p>
      {icon && (
        <p className="pl-2 group:transition-all transition-transform group-hover:translate-x-2">
          {icon}
        </p>
      )}
      {loading && <p className="loader !ml-5"></p>}
    </button>
  )
}

export default Button
