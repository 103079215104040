import * as React from 'react'
import classNames from 'classnames'

const HamburgerButton = ({ isClicked = false, toggleMenu }) => {
  return (
    <>
      <button
        className="relative w-7 h-7 cursor-pointer select-none"
        onClick={toggleMenu}
      >
        <span
          className={classNames(
            'absolute top-[10px] right-0.5 w-5 h-px rotate-0 bg-secondary transition duration-[250ms] ease-in-out',
            {
              'rotate-[45deg] translate-y-[2px]': isClicked,
            }
          )}
        ></span>
        <span
          className={classNames(
            'absolute top-[17px] right-0.5 w-5 h-px rotate-0 bg-secondary transition duration-[250ms] ease-in-out',
            {
              'rotate-[-45deg] translate-y-[-5px]': isClicked,
            }
          )}
        ></span>
      </button>
    </>
  )
}

export default HamburgerButton
