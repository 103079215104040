export const useContent = (data, name) => ({
  ...data?.allMarkdownRemark?.edges[0]?.node?.frontmatter,
  ...(data?.list?.edges && {
    list: data.list.edges.map(v => v.node.frontmatter),
  }),
  ...(name &&
    data[name] &&
    data[name].edges && {
      [name]: data[name].edges.map(v => v.node.frontmatter),
    }),
  images: { ...data?.images },
})
