import * as React from 'react'
import classNames from 'classnames'
import { Link, navigate } from 'gatsby'
import { nanoid } from 'nanoid'
import { StaticImage } from 'gatsby-plugin-image'
import Button from '../../Button'

const NavigationMobile = ({
  navItems,
  toggleMenu,
  mobileMenu,
  isGrayBackground,
  location,
  socialLinks,
}) => {

  const handleContactButton = () => {
    navigate("/contact-us/")
    toggleMenu()
  }

  return (
    <nav
      className={classNames(
        'bg-white nav-animated flex w-full flex-col grow',
        {
          'h-screen': mobileMenu,
          'h-0 delay-1000': !mobileMenu,
          'bg-pale-grey': isGrayBackground,
        }
      )}
    >
      {/* Menu */}
      <div className="w-full basis-1/2 flex flex-col justify-center items-center gap-16 m-auto">
        {navItems.map(item => (
          <div
            key={item.text}
            className={classNames('animated nav', {
              'animated-on': mobileMenu,
            })}
          >
            <Link
              key={nanoid()}
              className={classNames('typo-medium-20 text-secondary', {
                'text-primary underline decoration-solid decoration-primary decoration-2 underline-offset-4':
                  location?.pathname === item.path,
              })}
              onClick={toggleMenu}
              to={item.path}
            >
              {item.text}
            </Link>
          </div>
        ))}
      </div>
      {/* Footer */}
      <div className="basis-1/3 flex flex-col justify-center items-center gap-6 mt-auto mb-10">
        <div
          className={classNames('animated social', {
            'animated-on': mobileMenu,
          })}
        >
          <Button text="Contact Us" onClick={handleContactButton} to="/contact-us" />
        </div>
        <div
          className={classNames('mt-4 animated social', {
            'animated-on': mobileMenu,
          })}
        >
          <p className="typo-medium-12 text-gray ">
            © Copyright 2022 APPS - All Rights Reserved
          </p>
        </div>
        <div
          className={classNames(
            'flex justify-center items-center gap-6 animated social',
            { 'animated-on': mobileMenu }
          )}
        >
          <a href={socialLinks.twitter} target="blank">
            <StaticImage
              src="../../../../assets/images/icons/svg/icon-twitter.svg"
              alt="twitter"
              width={24}
              quality={90}
              loading='eager'
            />
          </a>
          <a href={socialLinks.instagram} target="blank">
            <StaticImage
              src="../../../../assets/images/icons/svg/icon-instagram.svg"
              alt="instagram"
              width={24}
              quality={90}
              loading='eager'
            />
          </a>
          <a href={socialLinks.linkedin} target="blank">
            <StaticImage
              src="../../../../assets/images/icons/svg/icon-linkedin.svg"
              alt="linkedin"
              width={24}
              quality={90}
              loading='eager'
            />
          </a>
        </div>
      </div>
    </nav>
  )
}

export default NavigationMobile
