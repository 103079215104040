import * as React from 'react'
import { graphql, useStaticQuery } from 'gatsby'
import classNames from 'classnames'
import NavigationDesktop from './components/NavigationDesktop'
import NavigationMobile from './components/NavigationMobile'
import HamburgerButton from './components/HamburgerButton'
import {Logo, LogoMobile} from './components/Logo'
import { useContent } from '../../../hooks/useContent'
import ButtonLink from '../ButtonLink'

const navItems = [
  {
    text: 'Games',
    path: '/games',
  },
  {
    text: 'Careers',
    path: '/careers',
  },
  {
    text: 'Blog',
    path: '/blog',
  },
]

const Header = ({ location }) => {
  const data = useStaticQuery(graphql`
    query ($content: String = "meta") {
      allMarkdownRemark(
        filter: { frontmatter: { content: { eq: $content } } }
      ) {
        edges {
          node {
            id
            frontmatter {
              instagram
              twitter
              linkedin
            }
          }
        }
      }
    }
  `)
  const content = useContent(data)
  const [mobileMenu, setMobileMenu] = React.useState(false)

  const toggleMenu = () => {
    setMobileMenu(prev => !prev)
  }

  return (
    <>
      {/* Desktop Header */}
      <header className="header hidden lg:flex justify-start items-center py-6 select-none relative">
        <Logo />
        <NavigationDesktop navItems={navItems} location={location} />
        <div className="ml-auto">
          <ButtonLink text="Contact Us" to="/contact-us" />
        </div>
      </header>
      {/* Mobile Header */}
      <header
        className={classNames(
          'lg:hidden fixed top-0 left-0 w-full z-20 flex flex-col select-none overflow-hidden px-[15px] bg-white',
          { 'bg-pale-grey': location?.pathname === '/' }
        )}
      >
        <div className="header relative w-full flex justify-between items-center py-[20px]">
          <LogoMobile />
          <HamburgerButton isClicked={mobileMenu} toggleMenu={toggleMenu} />
        </div>
        <NavigationMobile
          navItems={navItems}
          socialLinks={content}
          toggleMenu={toggleMenu}
          mobileMenu={mobileMenu}
          isGrayBackground={location?.pathname === '/'}
          location={location}
        />
      </header>
    </>
  )
}

export default Header
