module.exports = [{
      plugin: require('../node_modules/gatsby-plugin-manifest/gatsby-browser.js'),
      options: {"plugins":[],"name":"APPS Mobile Game Development Company","short_name":"APPS","description":"Visit the website of APPS, a mobile game development company. Creator of A Word Puzzle Game which is the most downloaded word game in Turkey.","lang":"en","start_url":"/","background_color":"#ffffff","theme_color":"#ffffff","display":"standalone","icon":"/usr/src/app/frontend/static/images/favicon-100x100.png","icons":[{"src":"/usr/src/app/frontend/static/images/android-chrome-96x96.png","sizes":"96x96","type":"image/png"}],"scope":"/","shortcuts":[{"name":"Check Our Games","short_name":"Games","url":"/games"},{"name":"Careers at Apps","short_name":"Careers","url":"/careers"}],"legacy":true,"theme_color_in_head":true,"cache_busting_mode":"query","crossOrigin":"anonymous","include_favicon":true,"cacheDigest":"182e87d16ccdce37c00f8afa340a4eae"},
    },{
      plugin: require('../node_modules/gatsby-plugin-layout/gatsby-browser.js'),
      options: {"plugins":[],"component":"/usr/src/app/frontend/src/components/Layout/index.js"},
    },{
      plugin: require('../node_modules/gatsby-plugin-google-tagmanager/gatsby-browser.js'),
      options: {"plugins":[],"id":"GTM-WPJ6SPW","includeInDevelopment":true,"routeChangeEventName":"changed_route","enableWebVitalsTracking":true,"defaultDataLayer":{"type":"function","value":"function () {\n          if (typeof window !== 'undefined') {\n            return {\n              pageType: window?.pageType,\n            }\n          }\n        }"},"selfHostedOrigin":"https://www.googletagmanager.com"},
    },{
      plugin: require('../gatsby-browser.js'),
      options: {"plugins":[]},
    },{
      plugin: require('../node_modules/gatsby/dist/internal-plugins/partytown/gatsby-browser.js'),
      options: {"plugins":[]},
    }]
