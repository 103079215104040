import React from 'react'
import cx from 'classnames'

const Container = ({ children, className }) => {
  return (
    <div className={cx('container mx-auto', { [className]: !!className })}>
      {children}
    </div>
  )
}

const FullWidthBg = ({ children, className }) => {
  return (
    <div
      className={cx('w-full bg-gray-light', {
        [className]: Boolean(className),
      })}
    >
      <Container>{children}</Container>
    </div>
  )
}

Container.FullWidth = ({ className, children }) => (
  <FullWidthBg className={className}>{children}</FullWidthBg>
)

export default Container
