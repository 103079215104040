import React from 'react'
import { navigate } from 'gatsby'
import classNames from 'classnames'

const ButtonLink = ({ to, className, text = '', icon = null }) => {
  const handleClick = e => {
    navigate(to)
  }

  return (
    <button
      onClick={handleClick}
      className={classNames(
        'group w-auto flex justify-center items-center bg-secondary typo-bold-14 lg:typo-bold-16 text-white rounded p-4 px-8 hover:bg-[#ea4e3d] transition duration-200',
        { [className]: Boolean(className) }
      )}
    >
      <p className="whitespace-nowrap">{text}</p>
      {icon && (
        <p className="pl-2 group:transition-all transition-transform group-hover:translate-x-2">
          {icon}
        </p>
      )}
    </button>
  )
}

export default ButtonLink
