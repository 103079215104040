import { Link } from 'gatsby'
import { StaticImage } from 'gatsby-plugin-image'
import React from 'react'

export const Logo = () => {
  return (
    <Link to="/" alt="Apps main page">
      <StaticImage
        src="../../../../assets/images/logo/Logo_256x256.png"
        height={80}
        layout="fixed"
        placeholder="none"
        alt="APPS"
        quality={100}
        className="z-10"
        loading='eager'
      />
    </Link>
  )
}


export const LogoMobile = () => {
  return (
    <Link to="/" alt="Apps main page">
      <StaticImage
        src="../../../../assets/images/logo/Logo_256x256.png"
        height={40}
        layout="fixed"
        placeholder="none"
        alt="APPS"
        quality={100}
        className="z-10"
        loading='eager'
      />
    </Link>
  )
}
