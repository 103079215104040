import React from 'react'
import classNames from 'classnames'
import { Link } from 'gatsby'
import { nanoid } from 'nanoid'

const NavigationDesktop = ({ navItems, location }) => {
  return (
    <nav className="hidden lg:flex lg:justify-center	lg:items-center lg:gap-12 lg:ml-12">
      {navItems.map(item => (
        <Link
          key={`nav-desktop-${nanoid()}`}
          className={classNames(
            'typo-bold-16 text-secondary hover:text-primary',
            {
              'text-primary underline decoration-solid decoration-primary decoration-2 underline-offset-4':
                location?.pathname === item.path,
            }
          )}
          to={item.path}
        >
          {item.text}
        </Link>
      ))}
    </nav>
  )
}

export default NavigationDesktop
